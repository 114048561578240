const defaultOptions = {
    greedy: false,
    showMaskOnHover: false,
    showMaskOnFocus: false,
    clearmaskonlostfocus: false,
    placeholder: '',
    autoUnmask: true,
    removeMaskOnSubmit: true
};

const listOfMasksSelectors = [
    `.mask-phone`,
    `.mask-document`,
    `.mask-zip-code`,
    `.mask-number-address`,
    `.mask-number-only`,
    `.mask-money-single-cost`
];

/* Begin mask: PHONE */
var regExPhone;
export const phone = (datatable) => {
    regExPhone = /[\(\) \-\_\.]/g;
    let selector = `.mask-phone`,
        masks = {
            default: { ...defaultOptions, mask: "999999999999999" },
            10: { ...defaultOptions, mask: "(99) 9999-9999", }, // 10 Digit
            11: { ...defaultOptions, mask: "(99) 9 9999-9999", }, // 11 Digit
            13: { ...defaultOptions, mask: "+99 (99) 9 9999-9999", }, // 13 Digit
            12: { ...defaultOptions, mask: "+99 (99) 9999-9999", }, // 13 Digit
            9: { ...defaultOptions, mask: "9 9999-9999", }, // 9 Digit
            8: { ...defaultOptions, mask: "9999-9999", } // 8 Digit
        };
    setInitialMaskPhone(masks, selector);
    $(document).on(`keydown`, selector, eventPhoneMask);
    if (datatable) datatable.on(`kt-datatable--on-layout-updated`, () => {
        setInitialMaskPhone(masks, selector);
    });
};
function setInitialMaskPhone(masks, selector) {
    let val = $(selector);
    for (let i = 0; i < val.length; i++) {
        let item = val[i],
            len = (item.value || item.textContent).replace(regExPhone, '').length,
            method = masks[len];
        if (method) $(item).inputmask(method);
        else $(item).inputmask(masks[0]); // 10 Digit
    }
}
function eventPhoneMask(e) {
    if (e.keyCode == 9) return;
    let val = this.value.replace(regExPhone, '');
    switch (true) {
        case (e.keyCode == 8 || e.keyCode == 46):
        default:
            if (val.length > 10) e.preventDefault();
            $(this).inputmask({
                ...defaultOptions,
                mask: "(99) 9999-9999",
            });
            return;
        case (val.length >= 10):
            var mask = val.length >= 11 ? "+99 (99) 9 9999-9999" : "(99) 9 9999-9999";
            $(this).inputmask({
                ...defaultOptions,
                mask: mask,
            });
            return;
    }
}
/* End mask: PHONE */


/* Begin mask: DOCUMENT */
export const document = (datatable) => {
    let selector = `.mask-document`,
        regEx = /[\.\-\_\/]/g,
        masks = [{
            ...defaultOptions,
            mask: `999.999.999-99` // CPF - 0
        }, {
            ...defaultOptions,
            mask: `99.999.999/9999-99` // CNPJ - 1
        }];
    setInitialMaskDocument(regEx, masks, selector);

    $(selector).on(`keydown`, function (e) {
        if (e.keyCode == 9) return;
        let length = this.value.replace(regEx, '').length;
        switch (true) {
            case ((e.keyCode == 8 || e.keyCode == 46) && length <= 12):
                if (length == 12) e.preventDefault();
            default:
                $(this).inputmask(masks[0]); // CPF
                return;
            case (length >= 11):
                $(this).inputmask(masks[1]); // CPNJ
                return;
        }
    });

    if (typeof datatable?.on === 'function') datatable.on(`kt-datatable--on-layout-updated`, () => {
        setInitialMaskDocument(regEx, masks, selector);
    });
};
function setInitialMaskDocument(regEx, masks, selector) {
    let val = $(selector);
    for (let i = 0; i < val.length; i++) {
        let item = val[i],
            len = (item.value || item.textContent).replace(regEx, '').length;
        if (len > 11) $(item).inputmask(masks[1]); // CPNJ
        else $(item).inputmask(masks[0]); // CPF
    }
}
/* End mask: DOCUMENT */


/* Begin mask: ZIP-CODE */
export const zipCode = (datatable) => {
    let selector = `.mask-zip-code`,
        regEx = /[\.\-]/g,
        masks = [{
            ...defaultOptions,
            mask: `99.999-999`
        }]
    setInitialMaskZipCode(regEx, masks, selector);
    if (datatable) datatable.on(`kt-datatable--on-layout-updated`, () => {
        setInitialMaskZipCode(regEx, masks, selector);
    });
};
function setInitialMaskZipCode(regEx, masks, selector) {
    let val = $(selector);
    for (let i = 0; i < val.length; i++) {
        let item = val[i];
        $(item).inputmask(masks[0]);
    }
}
/* End mask: ZIP-CODE */

/* Begin mask: NUMBER_ADDRESS */
export const numberAddress = (datatable) => {
    let selector = `.mask-number-address`,
        regEx = /[]/,
        masks = [{
            ...defaultOptions,
            mask: `[9][9][9][9]9`
        }]
    setInitialMaskNumberAddress(regEx, masks, selector);
    if (datatable) datatable.on(`kt-datatable--on-layout-updated`, () => {
        setInitialMaskNumberAddress(regEx, masks, selector);
    });
};
function setInitialMaskNumberAddress(regEx, masks, selector) {
    let val = $(selector);
    for (let i = 0; i < val.length; i++) {
        let item = val[i];
        $(item).inputmask(masks[0]);
    }
}
/* End mask: NUMBER_ADDRESS */

/* Begin mask: NUMBER_ONLY */
export const numberOnly = (datatable) => {
    let selector = `.mask-number-only`,
        regEx = /[]/,
        masks = [{
            ...defaultOptions,
            groupSeparator: ".",
            radixPoint: ",",
            autoGroup: true,
            allowPlus: false,
            allowMinus: false,
            digits: 0,
            integerDigits: 7,
            rightAlign: false
        }]
    setInitialMaskNumberOnly(regEx, masks, selector);
    if (datatable) datatable.on(`kt-datatable--on-layout-updated`, () => {
        setInitialMaskNumberOnly(regEx, masks, selector);
    });
};
function setInitialMaskNumberOnly(regEx, masks, selector) {
    let val = $(selector);
    for (let i = 0; i < val.length; i++) {
        let item = val[i];
        $(item).inputmask("decimal", masks[0]);
    }
}
/* End mask: NUMBER_ONLY */

/* Begin mask: MONEY_SINGLE_COST */

export const setMoneySingleCost = (selector) => {
    let regEx = /[]/,
        masks = [{
            ...defaultOptions,
            autoGroup: true,
            allowPlus: false,
            allowMinus: false,
            digits: 5,
            // removeMaskOnSubmit: false,
            autoUnmask: false,
            rightAlign: false,
            groupSeparator: ".",
            radixPoint: ",",
            integerDigits: 1,
            onUnMask: (maskedValue, unmaskedValue, opts) => {
                return maskedValue.replace('.', '').replace(',', '.');
            }
        }];
    selector.inputmask("decimal", masks[0]);

};

export const moneySingleCost = (datatable, input = false) => {
    let selector = `.mask-money-single-cost`,
        regEx = /[]/,
        masks = [{
            ...defaultOptions,
            autoGroup: true,
            allowPlus: false,
            allowMinus: false,
            digits: 5,
            // removeMaskOnSubmit: false,
            autoUnmask: false,
            rightAlign: false,
            groupSeparator: ".",
            radixPoint: ",",
            integerDigits: 1,
            onUnMask: (maskedValue, unmaskedValue, opts) => {
                return maskedValue.replace('.', '').replace(',', '.');
            }
        }];
    setInitialMoneySingleCost(regEx, masks, input || selector);
    if (datatable) datatable.on(`kt-datatable--on-layout-updated`, () => {
        setInitialMoneySingleCost(regEx, masks, input || selector);
    });
};

function setInitialMoneySingleCost(regEx, masks, selector) {
    let val = $(selector);
    for (let i = 0; i < val.length; i++) {
        let item = val[i];
        $(item).inputmask("decimal", masks[0]);
    }
}
/* End mask: MONEY_SINGLE_COST */

/* Begin mask: MONEY_REGULAR */
export const moneyRegular = (datatable) => {
    let selector = `.mask-money-regular`,
        regEx = /[]/,
        masks = [{
            ...defaultOptions,
            autoGroup: true,
            allowPlus: false,
            allowMinus: true,
            digits: 2,
            autoUnmask: false,
            rightAlign: false,
            groupSeparator: ".",
            radixPoint: ",",
            integerDigits: 9,
            onUnMask: (maskedValue, unmaskedValue, opts) => {
                return maskedValue.replace(/\./g, '').replace(',', '.');
            }
        }];
    setInitialMoneySingleCost(regEx, masks, selector);
    if (datatable) datatable.on(`kt-datatable--on-layout-updated`, () => {
        setInitialMoneyRegular(regEx, masks, selector);
    });
};
function setInitialMoneyRegular(regEx, masks, selector) {
    let val = $(selector);
    for (let i = 0; i < val.length; i++) {
        let item = val[i];
        $(item).inputmask("decimal", masks[0]);
    }
}
/* End mask: MONEY_REGULAR */
