//#region - modules
import BasicNotification from './traits/BasicNotification';
//#endregion

//#region - general constants
const
    DEFAULT_USER_LOGO = ``,
    ID_TIME = '{TIME}',
    ID_BACKGROUND = '{BACKGROUND}',
    ID_UNREAD = '{UNREAD}',
    ID_USER_LOGO = '{USER_LOGO}',
    ID_ADDR = '{ADDR}',
    ID_MESSAGE = '{MESSAGE}',
    DEFAULT_NOTIFICATION = `<a href="#" class="kt-notification__item ${ID_UNREAD}" style="background-color: ${ID_BACKGROUND}">
        <div class="kt-notification__item-icon">
            <img src="${PATH_IMG_COMPANIES}${ID_USER_LOGO}" height="40" width="40" alt="${'data.name.substring(0, 1)'}">
        </div>
        <div class="kt-notification__item-details" style="padding-left: 1em">
            <div class="kt-notification__item-title mask-phone"> ${ID_ADDR} </div>
            <div class="kt-notification__item-title"> ${ID_MESSAGE} </div>
            <div class="kt-notification__item-time"> ${ID_TIME} </div>
        </div>
        </a>`;
//#endregion

class InboxNotification extends BasicNotification {
    constructor(initialData = []) {
        super();
        this.tab_pulse_id = '#inbox_notification_pulse';
        this.tab_pulse_selector = $(this.tab_pulse_id);
        this.tab_header = '#tab_header_inbox';
        this.tab_header_selector = $(this.tab_header);
        this.type = 'App\\Notifications\\InboxNotification';
        this.tab_id = '#topbar_notifications_inbox .kt-notification';
        this.tab_selector = $(this.tab_id);
        this.redirect_uri = 'reports/inbox';
        this.initialData(initialData);
    }

    createNotification(event) {
        return DEFAULT_NOTIFICATION
            .replace(ID_TIME, event.time)
            .replace(ID_USER_LOGO, event.logo)
            .replace(ID_ADDR, event.addr)
            .replace(ID_MESSAGE, event.message.substr(0, 37) + ( event.message.length > 37 ? '...' : '' ))
            .replace(ID_BACKGROUND, event.read_at ? 'white' : 'lightcyan')
            .replace(ID_UNREAD, event.read_at ? '' : 'unread');
    }
}

export default InboxNotification;
