import BaseItem from './traits/BaseItem';

const
    SELECTOR_ID     = '#campaign-section',
    USER_LOGO       = '{LOGO}',
    ALTERNATIVE     = '{ALTERNATIVE}',
    USER_NAME       = '{NAME}',
    STATUS          = '{STATUS}',
    PROGRESS        = '{PROGRESS}',
    CAMPAIGN        = '{CAMPAIGN}',
    BASE_CAMPAIGN   = `,
    <div class="kt-widget31">
        <div class="kt-widget31__item">
            <div class="kt-widget31__content">
                <div class="kt-widget31__pic kt-widget4__pic--pic">
                    <img class="kt-hidden logo" src="${USER_LOGO}">
                    <span class="kt-badge kt-badge--username kt-badge--unified-${STATUS} kt-badge--xl kt-badge--rounded kt-badge--bold">
                        ${ALTERNATIVE}
                    </span>
                </div>
                <div class="kt-widget31__info">
                    <span class="kt-widget31__username">
                        ${USER_NAME}
                    </span>
                    <p class="kt-widget31__text">
                        ${CAMPAIGN}
                    </p>
                </div>
            </div>
            <div class="kt-widget31__content">
                <div class="kt-widget31__progress">
                    <a href="#" class="kt-widget31__stats">
                        <span>${PROGRESS}%</span>
                    </a>
                    <div class="progress progress-sm">
                        <div class="progress-bar bg-${STATUS}" role="progressbar" style="width: ${PROGRESS}%" aria-valuenow="${PROGRESS}" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>`;

class CampaignItem extends BaseItem {
    constructor(data) {
        super(data);
        this.name               = data.description;
        this.status             = data.status.badge;
        this.progress           = data.progress.value;
        this.id_selector        = SELECTOR_ID;
        this.item               = this.prepare();
    }

    prepare() {
        return BASE_CAMPAIGN
        .replace(USER_LOGO, this.user_logo)
        .replace(USER_NAME, this.user_name)
        .replace(CAMPAIGN, this.name)
        .replace(ALTERNATIVE, this.alternative)
        .split(STATUS).join(this.status)
        .split(PROGRESS).join(this.progress)
    }
}

export default CampaignItem;
