import BaseItem from'./traits/BaseItem';

const
    SELECTOR_ID = '#inbox-section',
    USER_LOGO   = '{LOGO}',
    ALTERNATIVE = '{ALTERNATIVE}',
    MESSAGE     = '{MESSAGE}',
    ADDRESS     = '{ADDRESS}',
    DATE        = '{DATE}',
    BASE_INBOX  = `;
    <div class="kt-widget4">
            <div class="kt-widget4__item">
                <div class="kt-widget4__pic kt-widget4__pic--pic">
                    <img class="kt-hidden logo" src="${USER_LOGO}">
                    <span class="kt-badge kt-badge--username kt-badge--unified-info kt-badge--xl kt-badge--rounded kt-badge--bold">
                        ${ALTERNATIVE}
                    </span>
                </div>
                <div class="kt-widget4__info">
                    <p class="kt-widget4__text">
                        ${MESSAGE}
                    </p>
                    <span class="kt-widget4__username">
                        ${ADDRESS}
                    </span>
                </div>
                <span class="kt-widget4__item">${DATE}</span>
            </div>
        </div>
    </div>
    <div class="kt-margin-t-10"></div>`;

class InboxItem extends BaseItem {
    constructor(data) {
        super(data);
        this.message            = data.message;
        this.address            = data.source_addr;
        this.date               = data.received_date.split(" ")[1].substring(0, 5);
        this.id_selector        = SELECTOR_ID;
        this.item               = this.prepare();
    }

    prepare() {
        return BASE_INBOX
        .replace(USER_LOGO, this.user_logo)
        .replace(ALTERNATIVE, this.alternative)
        .replace(MESSAGE, this.message)
        .replace(ADDRESS, this.address)
        .replace(DATE, this.date);
    }
}

export default InboxItem;
