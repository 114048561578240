const ID_UNREAD = '{TIME}';
const ID_BACKGROUND = '{TITLE}';
const ID_TIME = '{UNREAD}';
const ID_CONTENT = '{CONTENTC}';

const DEFAULT_NOTIFICATION = `<a href="#" class="kt-notification__item ${ID_UNREAD}" style="background-color: ${ID_BACKGROUND}">
    <div class="kt-notification__item-icon">
        <i class="flaticon-circle" kt-font-info"></i>
    </div>
    <div class="kt-notification__item-details">
        <div class="kt-notification__item-title" style=" text-align: justify; ">
            Uma sms de um cliente foi bloqueada e está esperando confirmação. Conteúdo bloqueado:
        </div>
        <br>
        <div class="kt-notification__item-title row">
            <div class="col">
                <b>
                    ${ID_CONTENT}
                </b>
            </div>
        </div>
        <div class="kt-notification__item-time">
            ${ID_TIME}
        </div>
    </div>
    </a>`;

//#region - modules
import BasicNotification from './traits/BasicNotification';
//#endregion

class BlockedMessageNotification extends BasicNotification {
    constructor(initialData = []) {
        super();
        this.tab_pulse_id = '#report_notification_pulse';
        this.tab_pulse_selector = $(this.tab_pulse_id);
        this.tab_header = '#tab_header_report';
        this.tab_header_selector = $(this.tab_header);
        this.type = 'App\\Notifications\\BlockedMessageNotification';
        this.tab_id = '#topbar_notifications_events .kt-notification';
        this.tab_selector = $(this.tab_id);
        this.redirect_uri = auth_user_hierarchy == hierarchy_master_code ? 'block/pending' : '#';
        this.initialData(initialData);
    }

    createNotification(event) {
        return DEFAULT_NOTIFICATION
            .replace(ID_TIME, event.time)
            .replace(ID_BACKGROUND, event.read_at ? 'white' : 'lightcyan')
            .replace(ID_CONTENT, event.content)
            .replace(ID_UNREAD, event.read_at ? '' : 'unread');

    }
}

export default BlockedMessageNotification;
