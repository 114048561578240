const
    EMPTY_ID    = '#empty',
    LOGO_CLASS  = '.logo';

class BaseItem {
    constructor(data) {
        this.user_logo      = `${PATH_IMG_COMPANIES}${data.client_id}.png`;
        this.alternative    = (data.hierarchy.Cliente || data.client).substring(0, 1);
        this.user_name      = data.hierarchy.Cliente || data.client;
        this.id_selector    = '';
        this.item           = '';
    }

    appendItem() {
        this.notEmpty()
        $(this.id_selector).append(this.item);
        this.logo();
    }

    notEmpty() {
        $(this.id_selector).find(EMPTY_ID).remove();
    }

    logo() {
        const array = $(this.id_selector).find(LOGO_CLASS);
        var logo    = $(array[array.length - 1]);
        logo.one("load", function() {
           logo.removeClass('kt-hidden');
        });
    }
}

export default BaseItem;
