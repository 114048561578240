//#region - modules
import BasicNotification from './traits/BasicNotification';
//#endregion

//#region - general constants
const
    DEFAULT_USER_LOGO = ``,
    ID_TIME = '{TIME}',
    ID_ICON = '{ICON}',
    ID_NAME = '{NAME}',
    ID_STATUS = '{STATUS}',
    ID_STATUS_COLOR = '{STATUS_COLOR}',
    ID_STATUS_NAME = '{STATUS_NAME}',
    ID_BACKGROUND = '{BACKGROUND}',
    ID_UNREAD = '{UNREAD}',
    ID_TOTAL = '{TOTAL}',
    ID_INVALID = '{INVALID}',
    ID_ACCEPTED = '{ACCEPTED}',
    ID_STAT = '{STAT}',
    ID_USER_LOGO = '{USER_LOGO}',
    ID_INFO = '{INFO}',
    DEFAULT_NOTIFICATION =
    `<a href="#" class="kt-notification__item ${ID_UNREAD}" style="background-color: ${ID_BACKGROUND}">
        <div class="kt-notification__item-icon">
            <img src="${PATH_IMG_USER}${ID_USER_LOGO}" height="40" width="40" alt="${'data.name.substring(0, 1)'}">
        </div>
        <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">
                ${ID_NAME}
                <span class="kt-badge kt-badge--${ID_STATUS_COLOR} kt-badge--inline kt-badge--pill kt-badge--rounded">${ID_STATUS_NAME}</span>
            </div>
            <div class="kt-notification__item-title row">
                <div class="col-4 text-right">
                    <div class="row"> <div class="col"> Total: </div> </div>
                    <div class="row"> <div class="col"> Válidas: </div> </div>
                    <div class="row"> <div class="col"> Inválidas: </div> </div>
                    <div class="row"> <div class="col"> Observação: </div> </div>
                </div>
                <div class="col">
                    <div class="row"> <span class="mask-number-only"> ${ID_TOTAL} </span> </div>
                    <div class="row"> <span class="mask-number-only"> ${ID_ACCEPTED} </span> </div>
                    <div class="row"> <span class="mask-number-only"> ${ID_INVALID} </span> </div>
                    <div class="row"> <span class="row"> &nbsp${ID_INFO} </span> </div>
                </div>
            </div>
            <div class="kt-notification__item-time"> ${ID_TIME} </div>
        </div>
    </a>`;
//#endregion

class CampaignNotification extends BasicNotification {
    constructor(initialData = []) {
        super();
        this.tab_pulse_id = '#campaign_notification_pulse';
        this.tab_pulse_selector = $(this.tab_pulse_id);
        this.tab_header = '#tab_header_campaign';
        this.tab_header_selector = $(this.tab_header);
        this.type = 'App\\Notifications\\CampaignNotification';
        this.tab_id = '#topbar_notifications_campaigns .kt-notification';
        this.redirect_uri = 'campaigns';
        this.tab_selector = $(this.tab_id);
        this.initialData(initialData);
    }

    createNotification(event) {
        let newElement = document.createElement('div');
        newElement.innerHTML = DEFAULT_NOTIFICATION
            .replace(ID_TIME, event.time)
            .replace(ID_ICON, event.icon)
            .replace(ID_NAME, event.name)
            .replace(ID_STATUS, event.status)
            .replace(ID_BACKGROUND, event.read_at ? 'white' : 'lightcyan')
            .replace(ID_UNREAD, event.read_at ? '' : 'unread')
            .replace(ID_TOTAL, event.total)
            .replace(ID_INVALID, event.invalid_numbers)
            .replace(ID_ACCEPTED, event.accept_numbers)
            .replace(ID_STAT, event.stat)
            .replace(ID_USER_LOGO, event.logo)
            .replace(ID_INFO, event.info)
            .replace(ID_STATUS_COLOR, event.color)
            .replace(ID_STATUS_NAME, event.statName);
        return newElement;
    }
}

function medal(stat) {
    return `<span id="item-status-${i}" class="kt-badge kt-badge--${data.status.badge} kt-badge--inline kt-badge--pill kt-badge--rounded">${data.status.name}</span>`
}

export default CampaignNotification;
