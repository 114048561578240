const _ = require('lodash');
const numeral = require('numeral');
const moment = require('moment');
const axios = require('axios');
const io = require('socket.io-client');
const setMask = require("./custom/setMask");
require("./custom/plugins/validate.pt-br");
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window._ = _;
window.numeral = numeral;
window.setMask = setMask;
try {
    moment.locale('pt-BR');
    $('[data-switch=true]').bootstrapSwitch();
    $('.kt-selectpicker').selectpicker({
        noneSelectedText: "Nada selecionado",
        noneResultsText: "Nenhum resultado encontrado para {0}",
        showSubtext: true
    });
    window.blockPage = () => {
        KTApp.blockPage({
            message: "Por favor, aguarde..."
        });
    }

    numeral.register('locale', 'pt-BR', {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'K',
            million: 'M',
            billion: 'B',
            trillion: 'T'
        },
        /*ordinal : function (number) {
            return number === 1 ? '' : 'ème';
        },*/
        currency: {
            symbol: 'R$'
        }
    });

    numeral.locale('pt-BR');
} catch (e) {
    console.log(e.message);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = window.location.protocol;

$("#logout").on("click", async () => {
    KTApp.block("body", {
        message: "Por favor, aguarde..."
    });
    var response = await axios.post("/logout");
    if(response.status === 204) window.location = `/login`;
});

$("#profile").on("click", () => {
    window.location = "/profile/personal";
});

$("#conf").on("click", () => {
    window.location = "/profile/setting";
});
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

if (typeof auth_user_id != 'undefined') {
    window.io = io;

    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: window.location.hostname + ":" + window.location.port,
    });
}
