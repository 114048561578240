const
    ID_PULSE = '#notification_pulse',
    ID_TIME = '{TIME}',
    ID_ICON = '{ICON}',
    ID_MESSAGE = '{TITLE}',
    ID_STATUS = '{STATUS}',
    ID_BACKGROUND = '{BACKGROUND}',
    ID_UNREAD = '{UNREAD}',
    DEFAULT_NOTIFICATION = `<a href="#" class="kt-notification__item ${ID_UNREAD}" style="background-color: ${ID_BACKGROUND}">
        <div class="kt-notification__item-icon">
            <i class="${ID_ICON} kt-font-${ID_STATUS}"></i>
        </div>
        <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">
                ${ID_MESSAGE}
            </div>
            <div class="kt-notification__item-time">
                ${ID_TIME}
            </div>
        </div>
    </a>`;

class BasicNotification {
    constructor() {
        this.tab_pulse_id = '';
        this.tab_pulse_selector = '';
        this.tab_header = '';
        this.tab_header_selector = '';
        this.type = '';
        this.tab_id = '';
        this.redirect_uri = '';
        this.tab_selector = '';
    }

    setEvents() {
        this.tab_header_selector.on('shown.bs.tab', () => {
            this.hideTabPulse();
            this.markAsRead();
        });
    }

    initialData(data) {
        var read_at = true;
        for (const item of data) {
            if (item.type != this.type) continue;
            let parsed = JSON.parse(item.data),
                time = makeTimeAgo(item.created_at),
                newNotification = this.createNotification({ ...parsed, time, read_at });
            this.addNotificationEvents(newNotification);
            read_at = read_at && item.read_at;
            this.appendNotification(newNotification);
        }
        if (!read_at) {
            this.showTabPulse();
            showPulse();
        }
        this.setEvents();
        setMask.numberOnly();
        setMask.phone();
    }

    notify(event) {
        let time = makeTimeAgo(moment() - 1000),
            newNotification = this.createNotification({ ...event, time, });
        this.addNotificationEvents(newNotification);
        this.prependNotification(newNotification);
        showPulse();
        this.showTabPulse();
        setMask.numberOnly();
        setMask.phone();
    }

    removeEmptyMessage() {
        this.tab_selector.find('#empty').remove();
    }

    prependNotification(notification) {
        this.removeEmptyMessage();
        this.tab_selector.prepend(notification);
    }

    appendNotification(notification) {
        this.removeEmptyMessage();
        this.tab_selector.append(notification);
    }

    showTabPulse() {
        this.tab_pulse_selector.show();
    }

    hideTabPulse() {
        this.tab_pulse_selector.hide();
    }

    markAsRead() {
        let unreadList = $(`${this.tab_id} a.unread`);
        if (unreadList.length) {
            setTimeout(() => unreadList.css({ "background-color": "white" }).removeClass("unread"), 2000);
            axios.get(`/users/markAsRead/${encodeURIComponent(this.type)}`);
        }
    }

    createNotification({ message, time, icon, status, read_at } = { message: '', time: '', icon: '', status: '', read_at: '' }) {
        let newElement = document.createElement('div');
        newElement.innerHTML = DEFAULT_NOTIFICATION
            .replace(ID_TIME, time)
            .replace(ID_ICON, icon)
            .replace(ID_MESSAGE, message)
            .replace(ID_STATUS, status)
            .replace(ID_BACKGROUND, read_at ? 'white' : 'lightcyan')
            .replace(ID_UNREAD, read_at ? '' : 'unread');
        return newElement;
    }

    addNotificationEvents(newElement) {
        $(newElement).on('click', () => this.onNotificationClick());
    }

    onNotificationClick(e) {
        if (e) e.preventDefault();
        window.location.href = `${window.location.origin}/${this.redirect_uri}`;
    }
}

function makeTimeAgo(datetime) {
    let interval = moment() - moment(datetime),
        messages = {
            seconds: () => 'Alguns segundos atrás',
            others: (number, size) => `${number} ${size} atrás`,
        };

    let count = parseInt(interval / (1000 * 60 * 60 * 24));
    if (count) {
        return messages.others(count, 'dias');
    }

    count = parseInt(interval / (1000 * 60 * 60));
    if (count) {
        return messages.others(count, 'horas');
    }

    count = parseInt(interval / (1000 * 60));
    if (count) {
        return messages.others(count, 'minutos');
    }

    return messages.seconds();
}

function showPulse() {
    $(ID_PULSE).show();
}

function hidePulse() {
    $(ID_PULSE).hide();
}

export default BasicNotification;
