//#region - modules
import BasicNotification from './traits/BasicNotification';
//#endregion

class ReportNotification extends BasicNotification {
    constructor(initialData = []) {
        super();
        this.tab_pulse_id = '#report_notification_pulse';
        this.tab_pulse_selector = $(this.tab_pulse_id);
        this.tab_header = '#tab_header_report';
        this.tab_header_selector = $(this.tab_header);
        this.type = 'App\\Notifications\\ReportsNotification';
        this.tab_id = '#topbar_notifications_events .kt-notification';
        this.tab_selector = $(this.tab_id);
        this.redirect_uri = 'files';
        this.initialData(initialData);
    }
}

export default ReportNotification;
