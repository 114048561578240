//#region - modules
import ReportNotification from './notifications/ReportNotification';
import InboxNotification from './notifications/InboxNotification';
import CampaignNotification from './notifications/CampaignNotification';
import BlacklistNotification from './notifications/BlacklistNotification';
import DoNotDisturbNotification from './notifications/DoNotDisturbNotification';
import CadupNotification from './notifications/CadupNotification';
import BlockedMessageNotification from './notifications/BlockedMessageNotification';
import CreditAlertNotification from './notifications/CreditAlertNotification';
import TenantNotification from './notifications/TenantNotification';
import jQuery from "jquery";
//#endregion - modules

//#region - Variables
var notifiers = {};

var ReportObject;
var InboxOnject;
var CampaignObject;
var TenantObject;
var DoNotDisturbObject;
var CadupObject;
var BlacklistObject;
var BlockedObject;
var CreditAlertObject;
//#endregion

jQuery(document).ready(function () {
    if (typeof auth_user_id != 'undefined') {
        initiate();
        window
            .Echo
            .channel(`users.${auth_user_id}`)
            .notification((event) => {
                if(!event) return;
                let type = event.type,
                    notifier = notifiers[type];
                if (!notifier) return;
                notifier.notify(event);
            });

        window
            .Echo
            .channel(`company.${auth_company_id}`)
            .notification((event) => {
                if(!event) return;
                let type = event.type,
                    notifier = notifiers[type];
                if (!notifier) return;
                notifier.notify(event);
            });
        $(document).on('show.bs.dropdown', '#notification_dropdown', markAsRead)
    }
});

async function initiate() {
    let initialData = await getInitalData();
    instantiateClasses(initialData);
    setNotifiers();
}

function getInitalData() {
    return new Promise((resolve) => {
        axios
            .get('/users/initialNotifications')
            .then((response) => {
                resolve(response.data);
            });
    });
}

function instantiateClasses(initialData) {
    ReportObject = new ReportNotification(initialData);
    CampaignObject = new CampaignNotification(initialData);
    InboxOnject = new InboxNotification(initialData);
    TenantObject = new TenantNotification(initialData);
    BlacklistObject = new BlacklistNotification(initialData);
    DoNotDisturbObject = new DoNotDisturbNotification(initialData);
    CadupObject = new CadupNotification(initialData);
    BlockedObject = new BlockedMessageNotification(initialData);
    CreditAlertObject = new CreditAlertNotification(initialData);
}

function setNotifiers() {
    notifiers[ReportObject.type] = ReportObject;
    notifiers[CampaignObject.type] = CampaignObject;
    notifiers[InboxOnject.type] = InboxOnject;
    notifiers[TenantObject.type] = TenantObject;
    notifiers[BlacklistObject.type] = BlacklistObject;
    notifiers[DoNotDisturbObject.type] = DoNotDisturbObject;
    notifiers[CadupObject.type] = CadupObject;
    notifiers[BlockedObject.type] = BlockedObject;
    notifiers[CreditAlertObject.type] = CreditAlertObject;
}

function markAsRead(e) {
    $('#notification_pulse').hide();
}
