import InboxItem    from './menu/InboxItem';
import CampaignItem from './menu/CampaignItem';
import jQuery from "jquery";

const
    CAMPAIGN_URL    = '/campaigns/find/all',
    CAMPAIGN_QTD_ID = '#qtd-campanhas',
    MO_URL          = '/reports/inbox/report',
    MO_QTD_ID       = '#qtd-recebidos';

jQuery(document).ready(function () {
    if (typeof auth_user_id != 'undefined') {
        handleCampaign();
        handleMO();
    }

    if(auth_user_hierarchy > hierarchy_system_code) {
        $('#company_id').on("click", copyToClipboard);
    }

    if(auth_user_hierarchy === hierarchy_client_code) {
        $('#api_token').on("click", copyToClipboard);
    }
});

function handleCampaign() {
    const date = moment().format("DD/MM/YYYY");
    const url = `${CAMPAIGN_URL}?date_init=${date} 00:00 - ${date} 23:59&status=1`;
    axios.get(url).then(response => {
        setCampaignQtd(response.data.meta.total);
        response.data.data.slice(0, 5).forEach(element => {
            new CampaignItem(element).appendItem();
        });
    }).catch(error =>{
        console.log(error);
    });
}

function handleMO() {
    const date = moment().format("YYYY-MM-DD");
    const url = `${MO_URL}?createdCheck=true&createDateStart=${date} 00:00:00&createDateEnd=${date} 23:59:59`;
    axios.get(url).then(response => {
        setMoQtd(response.data.meta.total);
        response.data.data.slice(0, 5).forEach(element => {
            new InboxItem(element).appendItem();
        });
    }).catch(error =>{
        console.log(error);
    });
}

function byDate(a, b) {
    if (a.date < b.date) {
        return -1
    }

    if (a.date > b.date) {
        return 1
    }

    return 0
}

function byStatus(element) {
    return element.status.code == 1;
}

function setCampaignQtd(qtd) {
    $(CAMPAIGN_QTD_ID).html(qtd);
}

function setMoQtd(qtd) {
    $(MO_QTD_ID).html(qtd);
}

function copyToClipboard() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(this).text()).select();
    document.execCommand("copy");
    $temp.remove();
}
