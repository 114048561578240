//#region - modules
import BasicNotification from './traits/BasicNotification';
//#endregion

class BlacklistNotification extends BasicNotification {
    constructor(initialData = []) {
        super();
        this.tab_pulse_id = '#report_notification_pulse';
        this.tab_pulse_selector = $(this.tab_pulse_id);
        this.tab_header = '#tab_header_report';
        this.tab_header_selector = $(this.tab_header);
        this.type = 'App\\Notifications\\BlacklistNotification';
        this.tab_id = '#topbar_notifications_events .kt-notification';
        this.tab_selector = $(this.tab_id);
        this.redirect_uri = 'blacklists';
        this.initialData(initialData);
    }
}

export default BlacklistNotification;
