"use strict";
// Class definition
const notifyStandart = ({ title, text, type }) => {
    swal.fire({
        title: title,
        html: text,
        type: type,
        buttonsStyling: false,
        confirmButtonText: "OK",
        confirmButtonClass: "btn btn-sm btn-bold btn-brand",
    });
};

window.alert = {
    question: ({ text, title, confirmButton, cancelButton, cancelColorStyle, confirmColorStyle, successCallback, errorCallback, type } =
        { text: '', title: '', confirmButton: '', cancelButton: '', cancelColorStyle: '', confirmColorStyle: '', successCallback: () => { }, errorCallback: () => { } }) => {
        swal.fire({
            buttonsStyling: false,

            html: text,
            type: type,

            confirmButtonText: confirmButton,
            confirmButtonClass: `btn btn-sm btn-bold btn-${confirmColorStyle}`,

            showCancelButton: true,
            cancelButtonText: cancelButton,
            cancelButtonClass: `btn btn-sm btn-bold btn-${cancelColorStyle}`
        })
            .then(successCallback)
            .catch(errorCallback);
    },
    axios: ({ text, textSuccess, textFailed, action, method, url, params, status, type, callback, errorCallback }) => {
        swal.fire({
            buttonsStyling: false,

            html: text,
            type: type,

            confirmButtonText: `Sim, ${action}!`,
            confirmButtonClass: "btn btn-sm btn-bold btn-brand",

            showCancelButton: true,
            cancelButtonText: "Não, cancelar.",
            cancelButtonClass: "btn btn-sm btn-bold btn-danger"
        }).then(function (result) {
            if (result.value) {
                KTApp.block("body", {
                    message: "Por favor, aguarde..."
                });

                axios[method](url, params)
                    .then((response) => {
                        if (response.status === status) {
                            KTApp.unblockPage();
                            swal.fire({
                                title: 'Sucesso!',
                                html: textSuccess,
                                type: 'success',
                                buttonsStyling: false,
                                confirmButtonText: "OK",
                                confirmButtonClass: "btn btn-sm btn-bold btn-brand",
                            }).then(callback(response));
                        } else {
                            KTApp.unblockPage();
                            errorCallback = errorCallback || function (response) { };
                            swal.fire({
                                title: 'Cancelado',
                                html: textFailed,
                                type: 'error',
                                buttonsStyling: false,
                                confirmButtonText: "OK",
                                confirmButtonClass: "btn btn-sm btn-bold btn-brand",
                            }).then(errorCallback(response));
                        }
                    }).catch(error => {
                        KTApp.unblockPage();
                        textFailed = textFailed || error.response.data.error_message;
                        errorCallback = errorCallback || function (error) { };
                        swal.fire({
                            title: 'Cancelado',
                            html: getErrorMessage(textFailed, error.response.status),
                            type: 'error',
                            buttonsStyling: false,
                            confirmButtonText: "OK",
                            confirmButtonClass: "btn btn-sm btn-bold btn-brand",
                        }).then(errorCallback(error));
                    });
            } else if (result.dismiss === 'cancel') {
                swal.fire({
                    title: 'Cancelado',
                    html: textFailed,
                    type: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "OK",
                    confirmButtonClass: "btn btn-sm btn-bold btn-brand",
                });
            }
        });
    },
    warning: ({ title, text }) => {
        notifyStandart({
            title,
            text,
            type: "warning"
        });
    },
    danger: ({ title, text }) => {
        notifyStandart({
            title,
            text,
            type: "error"
        });
    },
    success: ({ title, text }) => {
        notifyStandart({
            title,
            text,
            type: "success"
        });
    },
};

function getErrorMessage(message, status) {
    if (status == 401 && message.indexOf("company user's schedule") != -1) return "Este centro de custo não possui permissão para enviar neste horário.";
    if (status == 401 && message.indexOf("has insufficient balance") != -1) return "Saldo insuficiente para realizar esta ação.";
    return message;
}
