//#region - constants
const
    ID_TIME = '{TIME}',
    ID_ICON = '{ICON}',
    ID_MESSAGE = '{TITLE}',
    ID_STATUS = '{STATUS}',
    ID_BACKGROUND = '{BACKGROUND}',
    ID_UNREAD = '{UNREAD}',
    ID_TOTAL = 'TOTAL',
    ID_ACCEPTED = 'ACCEPTED',
    ID_INVALID = 'INVALID',
    DEFAULT_NOTIFICATION = `<a href="#" class="kt-notification__item ${ID_UNREAD}" style="background-color: ${ID_BACKGROUND}">
        <div class="kt-notification__item-icon">
            <i class="${ID_ICON} kt-font-${ID_STATUS}"></i>
        </div>
        <div class="kt-notification__item-details">
            <div class="kt-notification__item-title"> ${ID_MESSAGE} </div>
            <div class="kt-notification__item-title row">
                <div class="col-4 text-right">
                    <div class="row"> <div class="col"> Total: </div> </div>
                    <div class="row"> <div class="col"> Válidas: </div> </div>
                    <div class="row"> <div class="col"> Inválidas: </div> </div>
                </div>
                <div class="col">
                    <div class="row"> <span class="mask-number-only"> ${ID_TOTAL} </span> </div>
                    <div class="row"> <span class="mask-number-only"> ${ID_ACCEPTED} </span> </div>
                    <div class="row"> <span class="mask-number-only"> ${ID_INVALID} </span> </div>
                </div>
            </div>
            <div class="kt-notification__item-time">
                ${ID_TIME}
            </div>
        </div>
    </a>`;
//#endregion

//#region - modules
import BasicNotification from './traits/BasicNotification';
//#endregion

class CadupNotification extends BasicNotification {
    constructor(initialData = []) {
        super();
        this.tab_pulse_id = '#report_notification_pulse';
        this.tab_pulse_selector = $(this.tab_pulse_id);
        this.tab_header = '#tab_header_report';
        this.tab_header_selector = $(this.tab_header);
        this.type = 'App\\Notifications\\CadupNotification';
        this.tab_id = '#topbar_notifications_events .kt-notification';
        this.tab_selector = $(this.tab_id);
        this.redirect_uri = 'home';
        this.initialData(initialData);
    }

    createNotification(event) {
        return DEFAULT_NOTIFICATION
            .replace(ID_TIME, event.time)
            .replace(ID_ICON, event.icon)
            .replace(ID_STATUS, event.status)
            .replace(ID_MESSAGE, event.message.substr(0, 37) + ( event.message.length > 37 ? '...' : '' ))
            .replace(ID_BACKGROUND, event.read_at ? 'white' : 'lightcyan')
            .replace(ID_UNREAD, event.read_at ? '' : 'unread')
            .replace(ID_TOTAL, event.total_count || 0)
            .replace(ID_ACCEPTED, event.success_count || 0)
            .replace(ID_INVALID, event.error_count || 0);
    }
}

export default CadupNotification;
