window.KTAppOptions = {
    "colors": {
        "state": {
            "brand": "#5d78ff",
            "dark": "#282a3c",
            "light": "#ffffff",
            "primary": "#5867dd",
            "success": "#34bfa3",
            "info": "#36a3f7",
            "warning": "#ffb822",
            "danger": "#fd3995"
        },
        "base": {
            "label": [
                "#c5cbe3",
                "#a1a8c3",
                "#3d4465",
                "#3e4466"
            ],
            "shape": [
                "#f0f3ff",
                "#d9dffa",
                "#afb4d4",
                "#646c9a"
            ]
        }
    }
};

window.PATH_IMG_USER = `${import.meta.MIX_STORAGE_URL || "http://" + window.location.host}/${ import.meta.MIX_STORAGE_CONTAINER || "storage/images"}/users/`;
window.PATH_IMG_COMPANIES = `${import.meta.MIX_STORAGE_URL || "http://" + window.location.host}/${ import.meta.MIX_STORAGE_CONTAINER || "storage/images"}/companies/`;
window.states = [
    'success',
    'brand',
    'danger',
    'success',
    'warning',
    'primary',
    'info'
];

window.isActive = {
    false: {
        'title': 'Desativado',
        'class': ' btn-label-danger'
    },
    true: {
        'title': 'Ativado',
        'class': ' btn-label-success'
    }
};

window.DAYS_OF_WEEK = ['Domingo', 'Segunda', 'Terca', 'Quarta', 'Quinta', 'Sexta', 'Sabado'];
window.FORMAT_NUMBER = "0.00";
window.FORMAT_PRECISE_NUMBER = "0.00000";

window.filters = {
    master: `.filter-master`,
    resale: `.filter-resale`,
    client: `.filter-client`,
    vendor: '.filter-vendor',
    brush: '#filter-brush',
}

window.serverList = ['searchServer', 'modemServer'];

window.tooltipList = ['O servidor esta ativo!', 'Não houve resposta do servidor!'];

window.defaultBooleanTemplate = function(value, text = '') {
    return (value) ?
        `<span class="kt-font-success"><i class="flaticon2-check-mark"></i>${text}</span>` :
        `<span class="kt-font-danger"><i class="flaticon2-cross"></i>${text}</span>`;
}

window.defaultHasPriceTemplate = function (value) {
    return (value) ? numeral( value.replace('.',',') ).format( '$ ' + FORMAT_NUMBER ) : "-";
}

window.calculateFileSize = function(size) {
    let toFixed = 2;
    return (size > 999999999 ? ((size / 1000000000).toFixed(toFixed) + ' GB') :
        (size > 999999 ? ((size / 1000000).toFixed(toFixed) + ' MB') :
            ((size / 1000).toFixed(toFixed) + ' KB')));
}

window.rnMap = {
    '55321': 'Claro',

    '55331': 'Oi',
    '55314': 'Oi',
    '55335': 'Oi',

    '55399': 'Outras',

    '55341': 'Tim',

    '55315': 'Vivo',
    '55320': 'Vivo',
    '55323': 'Vivo',

    '55377': 'Nextel',
    '55351': 'Nextel',
}
